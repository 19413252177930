<template>
  <a-button
    class="editable-add-btn"
    @click="openModal"
    style="margin-bottom: 16px"
  >Добавить тип</a-button>

  <a-modal
    title="Добавить тип"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="create">ОК</a-button>
    </template>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Название">
        <a-input
          ref="posNameInput"
          placeholder="Введите название типа"
          v-model:value="posName" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import {ref, nextTick} from "vue";

const emit = defineEmits(['reload']);

let loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  posNameInput = ref("posNameInput"),
  posName = ref('');

const
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => posNameInput.value?.focus());
  },
  resetForm = () => {
    posName.value = '';
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;
    return apiClient
      .post('/store/create-pos-type', {
        name: posName.value,
      })
      .then(response => {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        emit('reload')
      })
  };

</script>
