<template>
  <div>
    <vb-create-pos-type @reload="reload()" />
    <vb-edit-pos-type
      v-if="modalVisible"
      :pos-id="posId"
      @reload="reload()"
      @hide-modal="hideModal()" />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать тип</template>
            <a @click="showModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить тип</template>
            <a @click="deleteModal = record.id"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      v-model:visible="deleteModal"
      @cancel="deleteModal = 0"
      :footer="null"
    >
      <a-typography-title :level="5">Вы действительно хотите удалить тип?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteType" type="primary" class="float-right">Да</a-button>
        <a-button @click="deleteModal = 0" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue';
import apiClient from "@/services/axios";
import VbCreatePosType from "@/views/pos/create";
import VbEditPosType from "@/views/pos/edit";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from "vue-router";

let
  posId = ref(0),
  dataSource = ref([]),
  imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  total = ref(0);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  deleteModal = ref(0),
  searchInput = ref(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 50,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '80px',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  showModal = (id) => {
    posId.value = id;
    modalVisible.value = true
  },
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  deleteType = () => {
    return apiClient.delete(`store/delete-pos-type?id=${deleteModal.value}`).then(response => {
      if (response) {
        message.success('Тип успешно удален')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    }).finally(() => {
      deleteModal.value = false
    });
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/store/pos-types', { params }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'pos-types',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>
