<template>
  <a-modal
    title="Редактировать тип"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="update">ОК</a-button>
    </template>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="spin-loader" v-if="fetching">
        <a-spin />
      </div>
      <div v-else>
        <a-form-item label="Название">
          <a-input
            ref="posNameInput"
            id="posname"
            placeholder="Введите название"
            v-model:value="posName" />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, onMounted, nextTick} from "vue";
import apiClient from "@/services/axios";

const props = defineProps({
  posId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let fetching = ref(true),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  posNameInput = ref("posNameInput"),
  posName = ref('');

const
  resetForm = () => {
    posName.value = '';
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    return apiClient
      .put(`/store/update-pos-type?id=${props.posId}`, {
        name: posName.value,
      })
      .then(response => {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible = false;
        confirmLoading.value = false;
        emit('reload')
        emit('hide-modal')
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

onMounted(() => {
  apiClient.get(`/store/pos-type?id=${props.posId}`).then(response => {
    if (response) {
      posName.value = response.data.data.name
    }
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    fetching.value = false;
    nextTick(() => posNameInput.value?.focus());
  })
});

</script>
